import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, Alert, Spin } from 'antd';
import './BatteryScreen.css'; // Assuming you have corresponding CSS

const BatteryScreen = () => {
  const { t } = useTranslation();
  const [batteryData, setBatteryData] = useState([]);
  const [latestCapacity, setLatestCapacity] = useState(null);
  const [timeCharging, setTimeCharging] = useState('0h 0m');
  const [timeDischarging, setTimeDischarging] = useState('0h 0m');
  const [maxSoC, setMaxSoC] = useState(0);
  const [minSoC, setMinSoC] = useState(100);
  const [averageSoC, setAverageSoC] = useState(0);
  const [chargingRate, setChargingRate] = useState(0);
  const [dischargingRate, setDischargingRate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        setError(null); // Reset previous errors

        const response = await axios.get('https://menea.krtech.dev/api/battery-data');
        const rawData = response.data;

        const sortedData = rawData.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));

        const data = sortedData.map((item) => ({
          time: item.DateTime,
          formattedTime: moment(item.DateTime).format('HH:mm'),
          SoCPercentage: parseFloat(item.SoC),
        }));

        setBatteryData(data);

        if (data.length === 0) {
          setError(t('battery.noDataAvailable'));
          setLoading(false);
          return;
        }

        // Calculate Highlights

        // Latest Capacity
        const latest = data[data.length - 1];
        setLatestCapacity(latest.SoCPercentage);

        // Time Spent Charging and Discharging
        let chargingIntervals = 0;
        let dischargingIntervals = 0;
        const intervalDurationMinutes = 5; // Set interval to 5 minutes

        for (let i = 1; i < data.length; i++) {
          const previous = data[i - 1].SoCPercentage;
          const current = data[i].SoCPercentage;

          if (current > previous) {
            chargingIntervals += 1;
          } else if (current < previous) {
            dischargingIntervals += 1;
          }
        }

        const convertMinutes = (minutes) => {
          const h = Math.floor(minutes / 60);
          const m = minutes % 60;
          return `${h}h ${m}m`;
        };

        setTimeCharging(convertMinutes(chargingIntervals * intervalDurationMinutes));
        setTimeDischarging(convertMinutes(dischargingIntervals * intervalDurationMinutes));

        // Calculate max, min, and average SoC
        const socValues = data.map(item => item.SoCPercentage);
        setMaxSoC(Math.max(...socValues));
        setMinSoC(Math.min(...socValues));
        setAverageSoC((socValues.reduce((a, b) => a + b, 0) / socValues.length).toFixed(2));

        // Calculate rate of charge/discharge
        const totalMinutes = data.length * intervalDurationMinutes;
        const chargeTime = chargingIntervals * intervalDurationMinutes;
        const dischargeTime = dischargingIntervals * intervalDurationMinutes;

        setChargingRate((socValues[socValues.length - 1] - socValues[0]) / chargeTime);
        setDischargingRate((socValues[0] - socValues[socValues.length - 1]) / dischargeTime);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching battery data:', error);
        setError(t('battery.errorFetchingData'));
        setLoading(false);
      }
    };

    fetchData();
  }, [t]); // Added 't' to dependency array for translation updates

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '100%',
    height: '500px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '20px',
  };

  const explanationStyle = {
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: '10px',
    marginBottom: '20px',
    maxWidth: '90%',
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{t('battery.batteryStateOfChargeOverTime')}</h2>

      {loading && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Spin tip={t('battery.loading')} />
        </div>
      )}

      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: '20px' }}
        />
      )}

      {!loading && !error && (
        <>
          <Card style={{ marginBottom: '20px' }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.latestCapacity')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {latestCapacity !== null ? `${latestCapacity}%` : '--'}
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.maxSoC')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {maxSoC}%
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.minSoC')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {minSoC}%
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.averageSoC')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {averageSoC}%
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.chargingRate')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {chargingRate.toFixed(2)}%/min
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.dischargingRate')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>
                    {dischargingRate.toFixed(2)}%/min
                  </p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.timeSpentCharging')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>{timeCharging}</p>
                </Card>
              </Col>

              <Col xs={24} sm={12} md={8}>
                <Card type="inner" title={t('battery.timeSpentDischarging')}>
                  <p style={{ fontSize: '24px', margin: 0 }}>{timeDischarging}</p>
                </Card>
              </Col>
            </Row>
          </Card>

          <div style={chartContainerStyle}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={batteryData}
                margin={{ top: 10, right: 30, left: 20, bottom: 40 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="formattedTime"
                  label={{
                    value: t('battery.timeHHmm'),
                    position: 'insideBottom',
                    offset: -5,
                  }}
                  tick={{ fontSize: 12 }}
                  angle={-45}
                  textAnchor="end"
                  height={80}
                  interval={Math.floor(batteryData.length / 10)}
                />
                <YAxis
                  label={{
                    value: t('battery.SoCPercentage'),
                    angle: -90,
                    position: 'insideLeft',
                    offset: 10,
                  }}
                  domain={[0, 100]}
                  tickFormatter={(value) => `${value}%`}
                />
                <Tooltip
                  labelFormatter={(value) => `${t('battery.time')}: ${value}`}
                  formatter={(value, name) => [`${value.toFixed(2)}%`, t('battery.stateOfCharge')]}
                />
                <Legend verticalAlign="top" height={36} />
                <Line
                  type="monotone"
                  dataKey="SoCPercentage"
                  stroke="#82ca9d"
                  name={t('battery.stateOfChargePercentage')}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <p style={explanationStyle}>
            {t('battery.explanation')}
          </p>
        </>
      )}
    </div>
  );
};

export default BatteryScreen;
