// src/contexts/MqttContext.js
import React, { createContext, useEffect, useState } from 'react';
import mqtt from 'mqtt';
import { v4 as uuidv4 } from 'uuid'; // For generating unique session IDs

export const MqttContext = createContext(null);

export const MqttProvider = ({ brokerUrl, topic, username, password, children }) => {
  const [client, setClient] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const sessionId = uuidv4(); // Unique identifier for this client

  useEffect(() => {
    // MQTT connection options
    const options = {
      clean: true,
      connectTimeout: 4000,
      // Authentication (if required)
      username: username || undefined,
      password: password || undefined,
      // Unique client ID
      clientId: `react-app-${uuidv4()}`,
      // Reconnection options
      reconnectPeriod: 1000,
    };

    // Initialize MQTT client
    const mqttClient = mqtt.connect(brokerUrl, options);

    // On connection
    mqttClient.on('connect', () => {
      console.log('Connected to MQTT broker');
      setIsConnected(true);
      mqttClient.subscribe(topic, (err) => {
        if (err) {
          console.error('Subscription error:', err);
        } else {
          console.log(`Subscribed to topic: ${topic}`);
        }
      });
    });

    // On error
    mqttClient.on('error', (err) => {
      console.error('Connection error:', err);
      mqttClient.end();
    });

    // On reconnect
    mqttClient.on('reconnect', () => {
      console.log('Reconnecting to MQTT broker...');
    });

    // Set client in state
    setClient(mqttClient);

    // Cleanup on unmount
    return () => {
      if (mqttClient.connected) {
        mqttClient.end();
      }
    };
  }, [brokerUrl, topic, username, password]);

  return (
    <MqttContext.Provider value={{ client, isConnected, sessionId }}>
      {children}
    </MqttContext.Provider>
  );
};
