import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card } from 'antd';

const ThermalEnergyScreen = () => {
  const { t } = useTranslation();
  const [combinedData, setCombinedData] = useState([]);
  const [ntData, setNtData] = useState([]);
  const [vtData, setVtData] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState('today');

  const API_BASE_URL = 'https://menea.krtech.dev/api'; // Adjust if necessary

  const timeRanges = [
    { key: 'today', label: t('thermalEnergy.today') },
    { key: 'last30Days', label: t('thermalEnergy.last30Days') },
    { key: 'lastYear', label: t('thermalEnergy.lastYear') },
  ];
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = moment();

        // Determine the start and end dates based on selected time range
        let startDate;
        let period;
        let resolution;

        if (selectedTimeRange === 'today') {
          startDate = now.startOf('day').format('YYYY-MM-DD');
          period = 'hour';
          resolution = 24;
        } else if (selectedTimeRange === 'last30Days') {
          startDate = now.clone().subtract(29, 'days').startOf('day').format('YYYY-MM-DD');
          period = 'day';
          resolution = 30;
        } else if (selectedTimeRange === 'lastYear') {
          startDate = now.clone().subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
          period = 'month';
          resolution = 12;
        }

        // Format function for date labels
        const formatDateLabel = (dateString) => {
          if (selectedTimeRange === 'today') {
            return moment(dateString).format('HH:mm');
          } else if (selectedTimeRange === 'last30Days') {
            return moment(dateString).format('DD.MM');
          } else if (selectedTimeRange === 'lastYear') {
            return moment(dateString).format('MMM YYYY');
          }
          return dateString;
        };

        // Fetch data from the API
        const heatPumpResponse = await axios.get(`${API_BASE_URL}/heatpump/data`, {
          params: {
            period: selectedTimeRange,
          },
        });

        const heatPumpRawData = heatPumpResponse.data;

        // Process heat pump data
        const dataMap = {};

        heatPumpRawData.forEach((dataPoint) => {
          const dateLabel = formatDateLabel(dataPoint.dateTime); // Assuming `dateTime` is the field from the API
          if (!dataMap[dateLabel]) {
            dataMap[dateLabel] = { date: dateLabel };
          }
          dataMap[dateLabel].total_energy_kwh = parseFloat(dataPoint.total_energy_kwh) || 0;
          dataMap[dateLabel].heatpump = dataPoint.heatpump || 0;
          dataMap[dateLabel].pyrolitic = dataPoint.pyrolitic || 0;
          dataMap[dateLabel].pirolitPećPlina = dataPoint.pirolitPećPlina || 0; // Add new category
          dataMap[dateLabel].solarniKolektori = dataPoint.solarniKolektori || 0; // Add new category
          // Add other categories as needed
        });

        // Convert dataMap to array and sort by date
        const combinedDataArray = Object.values(dataMap).sort((a, b) => {
          return moment(a.date, 'YYYY-MM-DD HH:mm').valueOf() - moment(b.date, 'YYYY-MM-DD HH:mm').valueOf();
        });
        const ntStart = moment().set({ hour: 21, minute: 0, second: 0 });
        const ntEnd = moment().set({ hour: 7, minute: 0, second: 0 });
        const vtStart = moment().set({ hour: 7, minute: 0, second: 0 });
        const vtEnd = moment().set({ hour: 21, minute: 0, second: 0 });

        const ntFiltered = combinedDataArray.filter((dataPoint) => {
          const pointDate = moment(`${moment().format('YYYY-MM-DD')} ${dataPoint.date}:00`, 'YYYY-MM-DD HH:mm:ss');
          console.log('Point Date:', pointDate.format('YYYY-MM-DD HH:mm:ss'), dataPoint);
          return pointDate.isBetween(ntStart, ntEnd, null, '[]');
        });

        console.log('NT Filtered Data:', ntFiltered);

        const vtFiltered = combinedDataArray.filter((dataPoint) => {
          const pointDate = moment(`${moment().format('YYYY-MM-DD')} ${dataPoint.date}:00`, 'YYYY-MM-DD HH:mm:ss');
          console.log('Point Date:', pointDate.format('YYYY-MM-DD HH:mm:ss'), dataPoint);
          return pointDate.isBetween(vtStart, vtEnd, null, '[]');
        });

        console.log('VT Filtered Data:', vtFiltered);

        setNtData(ntFiltered);
        setVtData(vtFiltered);
        setCombinedData(combinedDataArray);

      } catch (error) {
        console.error('Error fetching thermal energy data:', error);
      }
    };

    fetchData();
  }, [selectedTimeRange]);

  return (
    <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
      {/* Time Range Buttons */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        {timeRanges.map((range) => (
          <button
            key={range.key}
            style={{
              marginRight: '10px',
              padding: '10px 20px',
              cursor: 'pointer',
              backgroundColor: selectedTimeRange === range.key ? '#007bff' : '#e0e0e0',
              color: selectedTimeRange === range.key ? '#fff' : '#000',
            }}
            onClick={() => setSelectedTimeRange(range.key)}
          >
            {range.label}
          </button>
        ))}
      </div>

      <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>{t('thermalEnergy.thermalEnergyConsumption')}</h2>

      {/* Day Tariff Section */}
      <Card>
        <h3 style={{ textAlign: 'center' }}>{t('thermalEnergy.dayTariff')}</h3>
        <div style={{ width: '100%', height: '400px' }}>
          <ResponsiveContainer>
            <BarChart data={vtData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" angle={-45} textAnchor="end" height={80} />
              <YAxis />
              <Tooltip formatter={(value, name) => [`${value.toFixed(2)}`, name]} />
              <Legend />
              <Bar dataKey="total_energy_kwh" fill="#ffc658" name={t('thermalEnergy.heatpump')} />
              <Bar dataKey="pyrolitic" fill="#8884d8" name={t('thermalEnergy.pyrolitic')} />
              <Bar dataKey="pirolitPećPlina" fill="#82ca9d" name={t('thermalEnergy.piroliticPećPlina')} />
              <Bar dataKey="solarniKolektori" fill="#ff7300" name={t('thermalEnergy.solarni')} />
              {/* <Bar dataKey="total_energy_kwh" fill="#ffc658" name={t('thermalEnergy.vt')} /> */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>

      {/* Night Tariff Section */}
      <Card style={{ marginBottom: '20px' }}>
        <h3 style={{ textAlign: 'center' }}>{t('thermalEnergy.nightTariff')}</h3>
        <div style={{ width: '100%', height: '400px' }}>
          <ResponsiveContainer>
            <BarChart data={ntData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" angle={-45} textAnchor="end" height={80} />
              <YAxis />
              <Tooltip formatter={(value, name) => [`${value.toFixed(2)}`, name]} />
              <Legend />
              <Bar dataKey="heatpump" fill="#82ca9d" name={t('thermalEnergy.heatpump')} />
              <Bar dataKey="pyrolitic" fill="#8884d8" name={t('thermalEnergy.pyrolitic')} />
              <Bar dataKey="pirolitPećPlina" fill="#82ca9d" name={t('thermalEnergy.piroliticPećPlina')} />
              <Bar dataKey="solarniKolektori" fill="#ff7300" name={t('thermalEnergy.solarni')} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </div>
  );
};

export default ThermalEnergyScreen;
