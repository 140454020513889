// src/components/PeakLoadShavingScreen.jsx

import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  ReferenceLine,
} from 'recharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card, Row, Col } from 'antd';
import './PeakLoadShavingScreen.css';

const PeakLoadShavingScreen = () => {
  const { t } = useTranslation();

  // State variables
  const [data, setData] = useState([]);
  const [showcaseData, setShowcaseData] = useState([]);
  const [maxGridEnergy, setMaxGridEnergy] = useState(0);
  const [maxGridEnergyTime, setMaxGridEnergyTime] = useState('');
  const [totalBatteryEnergy, setTotalBatteryEnergy] = useState(0);
  const [batteryContributionPercentage, setBatteryContributionPercentage] = useState(0);

  // Peak Shaving Limits
  const peakShavingLimit1 = 5; // 5 kW limit
  const peakShavingLimit2 = 8; // 8 kW limit

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the main energy data
        const response = await axios.get('https://menea.krtech.dev/api/energy-data');
        const rawData = response.data;

        // Console log raw data for debugging
        console.log('Raw Data:', rawData);

        // Assuming data is per minute
        const deltaTimeInHours = 1 / 60;

        // Process data
        const processedData = rawData.map((item) => {
          const time = moment.utc(item.time).format('HH:mm');
          const homeUsage = typeof item.homeUsage === 'number' ? item.homeUsage : 0; // in kW
          const gridEnergy = typeof item.gridEnergy === 'number' ? item.gridEnergy : 0; // in kW

          // Energy per interval (kWh)
          const homeUsageEnergy = homeUsage * deltaTimeInHours;
          const gridEnergyEnergy = gridEnergy * deltaTimeInHours;
          const batteryEnergy = homeUsageEnergy - gridEnergyEnergy;

          return {
            time: time,
            gridPower: gridEnergy,
            homePower: homeUsage,
            gridEnergy: gridEnergyEnergy,
            homeEnergy: homeUsageEnergy,
            batteryEnergy: batteryEnergy > 0 ? batteryEnergy : 0,
          };
        });

        setData(processedData);

        // **Calculate Highlights**

        // 1. Maximum Grid Energy and Time
        const maxGrid = processedData.reduce(
          (max, item) => (item.gridPower > max.value ? { value: item.gridPower, time: item.time } : max),
          { value: 0, time: '' }
        );
        setMaxGridEnergy(maxGrid.value);
        setMaxGridEnergyTime(maxGrid.time);

        // 2. Total Battery Energy Used
        const totalBattery = processedData.reduce((sum, item) => sum + item.batteryEnergy, 0);
        setTotalBatteryEnergy(totalBattery.toFixed(2)); // Rounded to 2 decimal places

        // 3. Battery Contribution Percentage
        const totalHomeEnergy = processedData.reduce((sum, item) => sum + item.homeEnergy, 0);
        const batteryContribution = totalHomeEnergy > 0 ? (totalBattery / totalHomeEnergy) * 100 : 0;
        setBatteryContributionPercentage(batteryContribution.toFixed(2)); // Rounded to 2 decimal places

        // **Fetch Showcase Data**
        const showcaseResponse = await axios.get('https://menea.krtech.dev/api/energy-data-showcase');
        // const showcaseResponse = await axios.get('http://localhost:8080/energy-data-showcase');
        const rawShowcaseData = showcaseResponse.data;

        // Process showcase data
        const processedShowcaseData = rawShowcaseData.map((item) => {
          const time = moment.utc(item.time).format('HH:mm');
          const homeUsage = typeof item.homeUsage === 'number' ? item.homeUsage : 0; // in kW
          const gridEnergy = typeof item.gridEnergy === 'number' ? item.gridEnergy : 0; // in kW

          return {
            time: time,
            gridPower: gridEnergy,
            homePower: homeUsage,
          };
        });

        setShowcaseData(processedShowcaseData);
      } catch (error) {
        console.error('Error fetching energy data:', error);
      }
    };

    fetchData();
  }, []);

  // Styles
  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
    backgroundColor: '#f0f2f5',
  };

  const chartContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{t('peakLoadShaving.peak_load_shaving')}</h2>

      {/* Highlights Card */}
      <Card style={{ marginBottom: '20px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card type="inner">
              <p>
                <strong>{t('peakLoadShaving.maximumGridEnergy')}:</strong> {maxGridEnergy} kW{' '}
                {t('peakLoadShaving.at')} {maxGridEnergyTime}
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card type="inner">
              <p>
                <strong>{t('peakLoadShaving.totalBatteryEnergyUsed')}:</strong> {totalBatteryEnergy}{' '}
                kWh
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card type="inner">
              <p>
                <strong>{t('peakLoadShaving.batteryContributionPercentage')}:</strong>{' '}
                {batteryContributionPercentage}%
              </p>
            </Card>
          </Col>
        </Row>
      </Card>

      {/* Peak Shaving Limit 1: 5 kW Chart */}
      <Card title={t('peakLoadShaving.peakShavingLimit', { limit: peakShavingLimit1 })} style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart data={data}>
                <defs>
                  <linearGradient id="colorGridEnergy1" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorHomeUsage1" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                {/* Peak Shaving Limit */}
                <ReferenceLine
                  y={peakShavingLimit1}
                  label={`${t('peakLoadShaving.peakShavingLimit')} (${peakShavingLimit1} kW)`}
                  stroke="red"
                  strokeDasharray="5 5"
                />
                {/* Grid Power (With Battery) */}
                <Area
                  type="monotone"
                  dataKey="gridPower"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorGridEnergy1)"
                  name={t('peakLoadShaving.withBattery')}
                />
                {/* Home Power Usage (Without Battery) */}
                <Area
                  type="monotone"
                  dataKey="homePower"
                  stroke="#82ca9d"
                  fillOpacity={0.5}
                  fill="url(#colorHomeUsage1)"
                  name={t('peakLoadShaving.withoutBattery')}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Card>

      {/* Showcase Day Chart */}
      <Card title={t('peakLoadShaving.highlightDay')} style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart data={showcaseData}>
                <defs>
                  <linearGradient id="colorGridEnergyShowcase" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ff7300" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorHomeUsageShowcase" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#00c0ff" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#00c0ff" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                {/* Peak Shaving Limit */}
                <ReferenceLine
                  y={peakShavingLimit2}
                  label={`${t('peakLoadShaving.peakShavingLimit')} (${peakShavingLimit2} kW)`}
                  stroke="red"
                  strokeDasharray="5 5"
                />
                {/* Grid Power (With Battery) */}
                <Area
                  type="monotone"
                  dataKey="gridPower"
                  stroke="#ff7300"
                  fillOpacity={1}
                  fill="url(#colorGridEnergyShowcase)"
                  name={t('peakLoadShaving.withBattery')}
                />
                {/* Home Power Usage (Without Battery) */}
                <Area
                  type="monotone"
                  dataKey="homePower"
                  stroke="#00c0ff"
                  fillOpacity={0.5}
                  fill="url(#colorHomeUsageShowcase)"
                  name={t('peakLoadShaving.withoutBattery')}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PeakLoadShavingScreen;
