// src/components/Sidebar.jsx
import React, { useEffect, useContext, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MqttContext } from '../contexts/MqttContext';

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { client, isConnected, sessionId } = useContext(MqttContext);
  const publishTimeout = useRef(null); // For debouncing

  useEffect(() => {
    if (!client || !isConnected) return;

    const publishSelection = () => {
      const message = JSON.stringify({
        path: location.pathname,
        senderSessionId: sessionId,
      });

      client.publish(process.env.REACT_APP_MQTT_TOPIC, message, (err) => {
        if (err) {
          console.error('Publish error:', err);
        } else {
          console.log('Published:', message);
        }
      });
    };

    // Debounce publishing to prevent rapid multiple publishes
    if (publishTimeout.current) {
      clearTimeout(publishTimeout.current);
    }

    publishTimeout.current = setTimeout(() => {
      publishSelection();
    }, 300); // Adjust delay as needed

    return () => {
      if (publishTimeout.current) {
        clearTimeout(publishTimeout.current);
      }
    };
  }, [location, client, isConnected, sessionId]);

  const navStyle = {
    display: 'flex',
    flexDirection: 'row', // Arrange items horizontally
    justifyContent: 'center', // Center the navigation
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f0f0f0',
  };

  const linkStyle = {
    margin: '0 10px', // Horizontal margin
    textDecoration: 'none',
    color: '#333',
    padding: '5px 10px',
  };

  const activeLinkStyle = {
    fontWeight: 'bold',
    color: 'blue',
  };

  return (
    <nav style={navStyle}>
      <NavLink
        to="/peak-load-shaving"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.peak_load_shaving')}
      </NavLink>
      <NavLink
        to="/electric-energy"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.electric_energy')}
      </NavLink>
      <NavLink
        to="/thermal-energy"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.thermal_energy')}
      </NavLink>
      <NavLink
        to="/water-consumption"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.water_consumption')}
      </NavLink>
      <NavLink
        to="/ev-charging"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.ev_charging')}
      </NavLink>
      <NavLink
        to="/inverter"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.inverter')}
      </NavLink>
      <NavLink
        to="/battery"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.battery')}
      </NavLink>
      <NavLink
        to="http://10.1.10.100"
        style={({ isActive }) =>
          isActive ? { ...linkStyle, ...activeLinkStyle } : linkStyle
        }
      >
        {t('sidebar.StariSustav')}
      </NavLink>
    </nav>
  );
};

export default Sidebar;
