// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { MqttProvider } from './contexts/MqttContext';
import './index.css'; // Your global CSS

// Load environment variables
const MQTT_BROKER_URL = process.env.REACT_APP_MQTT_BROKER_URL;
const MQTT_TOPIC = process.env.REACT_APP_MQTT_TOPIC;
const MQTT_USERNAME = process.env.REACT_APP_MQTT_USERNAME;
const MQTT_PASSWORD = process.env.REACT_APP_MQTT_PASSWORD;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MqttProvider
      brokerUrl={MQTT_BROKER_URL}
      topic={MQTT_TOPIC}
      username={MQTT_USERNAME}
      password={MQTT_PASSWORD}
    >
      <Router>
        <App />
      </Router>
    </MqttProvider>
  </React.StrictMode>
);
