// src/components/Animation.jsx
import React from 'react';

const Animation = () => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  };

  const videoStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const logoStyle = {
    position: 'absolute',
    top: '20px', // Adjust the position as needed
    left: '20px', // Adjust the position as needed
    width: '350px', // Adjust the size as needed
    height: 'auto',
  };

  return (
    <div style={containerStyle}>
      <video
        style={videoStyle}
        src="MENEA.mp4"
        muted
        autoPlay
        loop
      />
      <img
        src="logo.png" // Replace with the path to your logo image
        alt="Logo"
        style={logoStyle}
      />
    </div>
  );
};

export default Animation;
