import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const InverterScreen = () => {
  const { t } = useTranslation();
  const [todayData, setTodayData] = useState({ highTariffData: [], lowTariffData: [] });
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [loading, setLoading] = useState(true);

  const API_BASE_URL = 'https://menea.krtech.dev/api'; // Adjust if necessary

  // Fetch today's data
  const fetchTodayData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/inverter/today`);
      const providedData = response.data;
      const values = providedData[0].values || [];

      const highTariffData = [];
      const lowTariffData = [];

      values.forEach((item) => {
        const hour = moment(item.time).hour();
        if (hour >= 7 && hour < 21) {
          // High tariff: 07:00 - 21:00
          highTariffData.push({ time: moment(item.time).format('HH:mm'), value: item.value / 1000 });
        } else if (hour >= 21 || hour < 7) {
          // Low tariff: 21:00 - 07:00
          lowTariffData.push({ time: moment(item.time).format('HH:mm'), value: item.value / 1000 });
        }
      });

      setTodayData({ highTariffData, lowTariffData });
    } catch (error) {
      console.error('Error fetching today data:', error.response?.data || error.message);
    }
  };

  const fetchMonthlyData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/inverter/monthly`);
      const providedData = response.data;
      const values = providedData[0].values || [];
      const data = values.map((item) => ({
        time: moment(item.time).format('DD MMM'),
        value: item.value / 1000, // Convert Wh to kWh
      }));
      setMonthlyData(data);
    } catch (error) {
      console.error('Error fetching monthly data:', error.response?.data || error.message);
    }
  };

  const fetchYearlyData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/inverter/yearly`);
      const providedData = response.data;
      const values = providedData[0].values || [];
      const data = values.map((item) => ({
        time: moment(item.time).format('MMM YYYY'),
        value: item.value / 1000, // Convert Wh to kWh
      }));
      setYearlyData(data);
    } catch (error) {
      console.error('Error fetching yearly data:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchTodayData();
      await fetchMonthlyData();
      await fetchYearlyData();
      setLoading(false);
    };
    fetchData();
  }, []);

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '400px',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        {t('inverter.loading')}
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{t('inverter.inverterData')}</h2>

      {/* Today's Data Chart */}
      <h3 style={titleStyle}>{t('inverter.todayProduction')}</h3>
      {todayData.highTariffData.length > 0 || todayData.lowTariffData.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart>
                {/* High Tariff Area */}
                {todayData.highTariffData.length > 0 && (
                  <>
                    <defs>
                      <linearGradient id="colorHighTariff" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#ff7300" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <Area
                      type="monotone"
                      dataKey="value"
                      data={todayData.highTariffData}
                      stroke="#ff7300"
                      fillOpacity={1}
                      fill="url(#colorHighTariff)"
                      name={t('inverter.highTariff')}
                    />
                  </>
                )}

                {/* Low Tariff Area */}
                {todayData.lowTariffData.length > 0 && (
                  <>
                    <defs>
                      <linearGradient id="colorLowTariff" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <Area
                      type="monotone"
                      dataKey="value"
                      data={todayData.lowTariffData}
                      stroke="#82ca9d"
                      fillOpacity={1}
                      fill="url(#colorLowTariff)"
                      name={t('inverter.lowTariff')}
                    />
                  </>
                )}

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  label={{
                    value: t('inverter.time'),
                    position: 'insideBottomRight',
                    offset: -5,
                  }}
                />
                <YAxis
                  unit="kW"
                  label={{ value: t('inverter.powerKw'), angle: -90, position: 'insideLeft' }}
                />
                <Tooltip
                  formatter={(value) => [`${value} kW`, t('inverter.power')]}
                  labelFormatter={(label) => `${t('inverter.time')}: ${label}`}
                />
                <Legend verticalAlign="top" height={36} />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>{t('inverter.noData')}</p>
      )}

      {/* Monthly Data Chart */}
      <h3 style={titleStyle}>{t('inverter.monthlyProduction')}</h3>
      {monthlyData.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart data={monthlyData}>
                <defs>
                  <linearGradient id="colorMonthly" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  label={{
                    value: t('inverter.date'),
                    position: 'insideBottomRight',
                    offset: -5,
                  }}
                />
                <YAxis
                  unit="kWh"
                  label={{ value: t('inverter.energyKwh'), angle: -90, position: 'insideLeft' }}
                />
                <Tooltip
                  formatter={(value) => [`${value} kWh`, t('inverter.energy')]}
                  labelFormatter={(label) => `${t('inverter.date')}: ${label}`}
                />
                <Legend verticalAlign="top" height={36} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorMonthly)"
                  name={t('inverter.production')}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>{t('inverter.noData')}</p>
      )}

      {/* Yearly Data Chart */}
      <h3 style={titleStyle}>{t('inverter.yearlyProduction')}</h3>
      {yearlyData.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart data={yearlyData}>
                <defs>
                  <linearGradient id="colorYearly" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  label={{
                    value: t('inverter.month'),
                    position: 'insideBottomRight',
                    offset: -5,
                  }}
                />
                <YAxis
                  unit="kWh"
                  label={{ value: t('inverter.energyKwh'), angle: -90, position: 'insideLeft' }}
                />
                <Tooltip
                  formatter={(value) => [`${value} kWh`, t('inverter.energy')]}
                  labelFormatter={(label) => `${t('inverter.month')}: ${label}`}
                />
                <Legend verticalAlign="top" height={36} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#ffc658"
                  fillOpacity={1}
                  fill="url(#colorYearly)"
                  name={t('inverter.production')}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>{t('inverter.noData')}</p>
      )}
    </div>
  );
};

export default InverterScreen;
