// src/components/WaterConsumptionScreen.jsx

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Spin } from 'antd'; // Using Ant Design's Spin component for loading indicator

const WaterConsumptionScreen = () => {
  const { t } = useTranslation();

  const [data24h, setData24h] = useState([]);
  const [data30d, setData30d] = useState([]);
  const [data12m, setData12m] = useState([]);
  const [loading, setLoading] = useState(true);

  // Base URL of your proxy server
  // const API_BASE_URL = 'http://localhost:8080'; // Adjust if necessary
  const API_BASE_URL = 'https://menea.krtech.dev/api'; // Adjust if necessary

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = moment();

        // Define requests for all three time ranges
        const requests = [];

        // Last 24 Hours
        const startDate24h = now.format('YYYY:M:D:0');
        const request24h = [
          {
            i: 0,
            t: 'c20231.voda_cnt[1]',
            p: 'day',
            s: startDate24h,
            sc: 1,
            cum: 1,
            res: 24,
            page: 615, // Adjust if necessary
          },
        ];
        requests.push(
          axios.post(`${API_BASE_URL}/cybro/graph`, request24h).then((response) => {
            const labels = response.data.labels || [];
            const values = response.data.data[0] || [];

            const combinedData = labels.map((label, index) => ({
              time: moment(label, 'MM/DD HH:mm').format('HH:mm'),
              waterConsumption: parseFloat(values[index]) || 0,
            }));

            setData24h(combinedData);
          })
        );

        // Last 30 Days
        const startDate30d = now.clone().subtract(29, 'days').format('YYYY:M:D:0');
        const request30d = [
          {
            i: 0,
            t: 'c20231.voda_cnt[1]',
            p: 'day',
            s: startDate30d,
            sc: 1,
            cum: 1,
            res: 30,
            page: 615, // Adjust if necessary
          },
        ];
        requests.push(
          axios.post(`${API_BASE_URL}/cybro/graph`, request30d).then((response) => {
            const labels = response.data.labels || [];
            const values = response.data.data[0] || [];

            const combinedData = labels.map((label, index) => ({
              date: moment(label, 'MM/DD HH:mm').format('DD.MM'),
              waterConsumption: parseFloat(values[index]) || 0,
            }));

            setData30d(combinedData);
          })
        );

        // Last Year
        const startDate12m = now.clone().subtract(11, 'months').startOf('month').format('YYYY:M:D:0');
        const request12m = [
          {
            i: 0,
            t: 'c20231.voda_cnt[1]',
            p: 'month',
            s: startDate12m,
            sc: 1,
            cum: 1,
            res: 12,
            page: 615, // Adjust if necessary
          },
        ];
        requests.push(
          axios.post(`${API_BASE_URL}/cybro/graph`, request12m).then((response) => {
            const labels = response.data.labels || [];
            const values = response.data.data[0] || [];

            const combinedData = labels.map((label, index) => ({
              month: moment(label, 'YYYY/MM').format('MMM YYYY'),
              waterConsumption: parseFloat(values[index]) || 0,
            }));

            setData12m(combinedData);
          })
        );

        // Wait for all requests to complete
        await Promise.all(requests);
      } catch (error) {
        console.error('Error fetching water consumption data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '400px',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  return (
    <div style={containerStyle}>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '100px' }}>
          <Spin size="large" tip={t('waterConsumption.loading')} />
        </div>
      ) : (
        <>
          <h2 style={titleStyle}>{t('waterConsumption.waterConsumption')}</h2>

          {/* Last 24 Hours Chart */}
          <h3 style={titleStyle}>{t('waterConsumption.last24Hours')}</h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={chartContainerStyle}>
              <ResponsiveContainer>
                <BarChart data={data24h}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" angle={-45} textAnchor="end" height={80} interval={0} />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Bar
                    dataKey="waterConsumption"
                    fill="#8884d8"
                    name={t('waterConsumption.waterConsumption')}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Last 30 Days Chart */}
          <h3 style={titleStyle}>{t('waterConsumption.last30Days')}</h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={chartContainerStyle}>
              <ResponsiveContainer>
                <BarChart data={data30d}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" angle={-45} textAnchor="end" height={80} interval={0} />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Bar
                    dataKey="waterConsumption"
                    fill="#82ca9d"
                    name={t('waterConsumption.waterConsumption')}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Last Year Chart */}
          <h3 style={titleStyle}>{t('waterConsumption.last12Months')}</h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={chartContainerStyle}>
              <ResponsiveContainer>
                <BarChart data={data12m}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" angle={-45} textAnchor="end" height={80} interval={0} />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Bar
                    dataKey="waterConsumption"
                    fill="#ffc658"
                    name={t('waterConsumption.waterConsumption')}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WaterConsumptionScreen;
