// src/App.js
import React, { useEffect, useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Animation from './components/Animation'; // Left section: Video
import ElectricEnergyScreen from './components/ElectricEnergyScreen';
import ThermalEnergyScreen from './components/ThermalEnergyScreen';
import WaterConsumptionScreen from './components/WaterConsumptionScreen';
import EVChargingScreen from './components/EVChargingScreen';
import InverterScreen from './components/InverterScreen';
import BatteryScreen from './components/BatteryScreen';
import PeakLoadShavingScreen from './components/PeakLoadShavingScreen';
import './App.css';
import './i18n'; // Import the i18n configuration
import { useTranslation } from 'react-i18next'; // For using translations
import Timer from './components/Timer';
import Sidebar from './components/Sidebar'; // Import Sidebar
import { MqttContext } from './contexts/MqttContext';
import { Button } from 'antd';


const App = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { client, isConnected, sessionId } = useContext(MqttContext);

  // Handle incoming MQTT messages to navigate
  useEffect(() => {
    if (!client || !isConnected) return;

    const handleMessage = (topic, message) => {
      if (topic === process.env.REACT_APP_MQTT_TOPIC) {
        try {
          const parsedMessage = JSON.parse(message.toString());
          const { path, senderSessionId } = parsedMessage;

          // Ignore messages sent by this client
          if (senderSessionId === sessionId) return;

          console.log('Received screen selection:', path);

          // Prevent navigation if already on the desired path
          if (path !== window.location.pathname) {
            navigate(path);
          }
        } catch (error) {
          console.error('Error parsing MQTT message:', error);
        }
      }
    };

    client.on('message', handleMessage);

    // Cleanup the message handler on unmount or client change
    return () => {
      client.off('message', handleMessage);
    };
  }, [client, isConnected, navigate, sessionId]);

  // Function to switch between languages
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="app">
      {/* Left section: Video */}
      <div className="left-section">
        <Animation />
      </div>

      {/* Right section */}
      <div className="right-section">
        {/* Header with Sidebar (Navigation), Timer, and Language Selector */}
        <div className="header">
          {/* Timer and Language Selector */}
          <div className="top-controls">
            
            <div className="language-selector">
              <Button onClick={() => changeLanguage('hr')}>HR</Button>
              <Button onClick={() => changeLanguage('en')}>EN</Button>
            </div>
          </div>

          {/* Place the Sidebar in the upper right */}
          <Sidebar />
        </div>

        {/* Main Content (Screens) */}
        <div className="content">
          <Routes>
            <Route path="/peak-load-shaving" element={<PeakLoadShavingScreen />} />
            <Route path="/electric-energy" element={<ElectricEnergyScreen />} />
            <Route path="/thermal-energy" element={<ThermalEnergyScreen />} />
            <Route path="/water-consumption" element={<WaterConsumptionScreen />} />
            <Route path="/ev-charging" element={<EVChargingScreen />} />
            <Route path="/inverter" element={<InverterScreen />} />
            <Route path="/battery" element={<BatteryScreen />} />
            <Route path="*" element={<PeakLoadShavingScreen />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
