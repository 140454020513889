import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Bar,
  BarChart,
  Line
} from 'recharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card } from 'antd';
import './ElectricalEnergyScreen.css';

const ElectricalEnergyScreen = () => {
  const { t } = useTranslation();

  const [todayVTUsage, setTodayVTUsage] = useState(0);
  const [todayNTUsage, setTodayNTUsage] = useState(0);
  const [todayData, setTodayData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);

  const API_BASE_URL = 'https://menea.krtech.dev/api';

  const isWinterTime = (date) => {
    const month = date.month();
    return month < 3 || month > 9; // Winter time: November to March
  };

  const getTariffType = (date) => {
    const hour = date.hour();
    const winter = isWinterTime(date);

    if (winter) {
      return hour >= 7 && hour < 21 ? 'VT' : 'NT';
    } else {
      return hour >= 8 && hour < 22 ? 'VT' : 'NT';
    }
  };
useEffect(() => {
  const fetchData = async () => {
    try {
      const todayResponse = await axios.get(`${API_BASE_URL}/electricity/home-energy-sources`);
      const todayRawData = todayResponse.data;

      console.log('Raw Today Data:', todayRawData); // Debugging log

      let vtTotal = 0;
      let ntTotal = 0;

      const processedTodayData = todayRawData.map((dataPoint, index) => {
        try {
          const dateTimeString = dataPoint.dateTime.replace(/^"|"$/g, '');
          const date = moment(dateTimeString, 'YYYY-MM-DD HH:mm:ss', true);

          if (!date.isValid()) {
            console.warn(`Invalid date format at index ${index}:`, dataPoint.dateTime);
            return null; // Skip invalid records
          }

          const tariff = getTariffType(date);
          const usage = parseFloat(dataPoint.homeUsage) || 0;

          if (tariff === 'VT') {
            vtTotal += usage;
          } else {
            ntTotal += usage;
          }

          return {
            date: date.format('HH:mm'),
            VT: tariff === 'VT' ? usage : 0,
            NT: tariff === 'NT' ? usage : 0,
          };
        } catch (error) {
          console.error(`Error processing data point at index ${index}:`, error);
          return null; // Skip problematic records
        }
      }).filter((item) => item !== null); // Filter out null entries

      console.log('Processed Today Data:', processedTodayData); // Debugging log

      setTodayVTUsage(vtTotal.toFixed(2));
      setTodayNTUsage(ntTotal.toFixed(2));
      setTodayData(processedTodayData);

      const monthResponse = await axios.get(
        `${API_BASE_URL}/electricity/month-home-energy-sources`
      );
      const monthRawData = monthResponse.data;

      const processedMonthlyData = monthRawData.map((dataPoint, index) => {
        try {
          const dateTimeString = dataPoint.dateTime.replace(/^"|"$/g, '');
          const dateLabel = moment(dateTimeString, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY');
          return {
            date: dateLabel,
            homeUsage: parseFloat(dataPoint.homeUsage) || 0,
          };
        } catch (error) {
          console.error(`Error processing monthly data point at index ${index}:`, error);
          return null; // Skip problematic records
        }
      }).filter((item) => item !== null);

      console.log('Processed Monthly Data:', processedMonthlyData); // Debugging log

      setMonthlyData(processedMonthlyData);
    } catch (error) {
      console.error('Error fetching electricity data:', error);
    }
  };

  fetchData();
}, []);


  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  const tariffInfo = (
    <div>
      <p>{t('winterTariff')}: {t('higherTariff')} 07:00 - 21:00, {t('lowerTariff')} 21:00 - 07:00</p>
      <p>{t('summerTariff')}: {t('higherTariff')} 08:00 - 22:00, {t('lowerTariff')} 22:00 - 08:00</p>
    </div>
  );

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{t('electricalEnergyConsumption')}</h2>

      {/* Tariff Information */}
      <Card title={t('tariffInformation')} style={{ marginBottom: '20px' }}>
        {tariffInfo}
      </Card>

      {/* Today's Usage Card */}
      <Card title={t('todayUsage')} style={{ marginBottom: '20px' }}>
        <p>{t('higherTariffUsage')}: {todayVTUsage} kWh</p>
        <p>{t('lowerTariffUsage')}: {todayNTUsage} kWh</p>
      </Card>

      {/* Today's Usage Chart */}
{/* Today's Usage Chart as Bar Chart */}
<Card title={t('todayUsageChart')} style={{ marginBottom: '20px' }}>
  <div style={chartContainerStyle}>
    <ResponsiveContainer>
      <BarChart data={todayData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          angle={-45}
          textAnchor="end"
          height={80}
          interval="preserveEnd"
        />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey="VT"
          fill="#82ca9d"
          name={t('VTUsage')}
          isAnimationActive={false}
        />
        <Bar
          dataKey="NT"
          fill="#8884d8"
          name={t('NTUsage')}
          isAnimationActive={false}
        />
      </BarChart>
    </ResponsiveContainer>
  </div>
</Card>


      {/* Monthly Usage Chart */}
      <Card title={t('monthlyElectricityUsage')} style={{ marginBottom: '20px' }}>
        <div style={chartContainerStyle}>
          <ResponsiveContainer>
            <LineChart data={monthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                angle={-45}
                textAnchor="end"
                height={80}
                interval="preserveEnd"
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="homeUsage"
                stroke="#8884d8"
                name={t('homeUsage')}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card>
    </div>
  );
};

export default ElectricalEnergyScreen;
