// src/components/EVChargingScreen.jsx
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import './EVChargingScreen.css'; // Import the CSS file for styling

const EVChargingScreen = () => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [batterySoC, setBatterySoC] = useState(100); // Battery State of Charge (%)

  // Battery Specifications (Ako je relevantno, inače možete ukloniti)
  const BATTERY_CAPACITY_KWH = 100; // Total energy capacity in kWh
  const BATTERY_MAX_DISCHARGE_KW = 20; // Max discharge rate in kW
  const BATTERY_MAX_CHARGE_KW = 20; // Max charge rate in kW

  // Ugrađeni Podaci o Sesijama Punjenja
  const chargingSessions = [
    {
      "sessionId": "C241125103302321179211",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "25/11/2024 11:33",
      "endTime": "25/11/2024 15:00",
      "duration": 3.45,
      "energyDelivered": 17.612,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C241112111005721179231",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "12/11/2024 12:10",
      "endTime": "12/11/2024 14:58",
      "duration": 2.8,
      "energyDelivered": 18.201,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C241104104004221179218",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "04/11/2024 11:40",
      "endTime": "04/11/2024 15:13",
      "duration": 3.55,
      "energyDelivered": 19.957,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24102910590032117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "29/10/2024 11:59",
      "endTime": "29/10/2024 15:00",
      "duration": 3.02,
      "energyDelivered": 19.427,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C241022104502821179214",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/10/2024 11:45",
      "endTime": "22/10/2024 12:49",
      "duration": 1.07,
      "energyDelivered": 7.157,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C241014120104421179215",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "14/10/2024 13:01",
      "endTime": "14/10/2024 13:58",
      "duration": 0.95,
      "energyDelivered": 6.618,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C241007092902921179211",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "07/10/2024 10:29",
      "endTime": "07/10/2024 15:10",
      "duration": 4.68,
      "energyDelivered": 23.312,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24092609170172117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "26/09/2024 10:17",
      "endTime": "26/09/2024 11:14",
      "duration": 0.96,
      "energyDelivered": 6.669,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C240925101203021179212",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "25/09/2024 11:12",
      "endTime": "25/09/2024 12:10",
      "duration": 0.96,
      "energyDelivered": 6.704,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24091608140532117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "16/09/2024 09:14",
      "endTime": "16/09/2024 12:29",
      "duration": 3.24,
      "energyDelivered": 21.349,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24082710540332117927",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "27/08/2024 11:54",
      "endTime": "27/08/2024 14:12",
      "duration": 2.3,
      "energyDelivered": 16.231,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24071709170362117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "17/07/2024 10:17",
      "endTime": "17/07/2024 13:01",
      "duration": 2.74,
      "energyDelivered": 19.211,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C240711050805421179218",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "11/07/2024 6:08",
      "endTime": "11/07/2024 7:10",
      "duration": 1.02,
      "energyDelivered": 7.169,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24070311150132117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "03/07/2024 12:15",
      "endTime": "03/07/2024 13:54",
      "duration": 1.65,
      "energyDelivered": 11.63,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C240612101204621179215",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "12/06/2024 11:12",
      "endTime": "12/06/2024 13:53",
      "duration": 2.68,
      "energyDelivered": 18.514,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24050609220412117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "06/05/2024 10:22",
      "endTime": "06/05/2024 12:37",
      "duration": 2.24,
      "energyDelivered": 15.783,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24042508520172117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "25/04/2024 09:52",
      "endTime": "25/04/2024 13:03",
      "duration": 3.18,
      "energyDelivered": 17.661,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24042207430222117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/04/2024 08:43",
      "endTime": "22/04/2024 10:33",
      "duration": 1.84,
      "energyDelivered": 7.297,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24041109200372117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "11/04/2024 10:20",
      "endTime": "11/04/2024 11:59",
      "duration": 1.64,
      "energyDelivered": 11.481,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24030510210112117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "05/03/2024 11:21",
      "endTime": "05/03/2024 13:18",
      "duration": 1.95,
      "energyDelivered": 13.688,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24030510200392117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "05/03/2024 11:20",
      "endTime": "05/03/2024 11:20",
      "duration": 0,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C24022013150502117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "20/02/2024 14:15",
      "endTime": "20/02/2024 15:05",
      "duration": 0.83,
      "energyDelivered": 4.847,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24021310340102117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "13/02/2024 11:34",
      "endTime": "13/02/2024 13:50",
      "duration": 2.27,
      "energyDelivered": 15.911,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C24013110320412117927",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "31/01/2024 11:32",
      "endTime": "31/01/2024 15:05",
      "duration": 3.55,
      "energyDelivered": 17.686,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C240111084705321179214",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "11/01/2024 9:47",
      "endTime": "11/01/2024 12:22",
      "duration": 2.58,
      "energyDelivered": 14.929,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23122206520292117927",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/12/2023 07:52",
      "endTime": "22/12/2023 10:33",
      "duration": 2.68,
      "energyDelivered": 17.894,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23122106080542117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "21/12/2023 07:08",
      "endTime": "21/12/2023 07:30",
      "duration": 0.36,
      "energyDelivered": 1.932,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23121806120262117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "18/12/2023 07:12",
      "endTime": "18/12/2023 11:19",
      "duration": 4.11,
      "energyDelivered": 11.97,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23120410430452117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "04/12/2023 11:43",
      "endTime": "04/12/2023 14:32",
      "duration": 2.82,
      "energyDelivered": 19.191,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23112306340302117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "23/11/2023 07:34",
      "endTime": "23/11/2023 10:36",
      "duration": 3.04,
      "energyDelivered": 19.974,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Remote"
    },
    {
      "sessionId": "C23112212240552117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/11/2023 13:24",
      "endTime": "22/11/2023 13:55",
      "duration": 0.51,
      "energyDelivered": 3.502,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23111606050122117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "16/11/2023 07:05",
      "endTime": "16/11/2023 08:50",
      "duration": 1.76,
      "energyDelivered": 12.309,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23111513300142117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "15/11/2023 14:30",
      "endTime": "15/11/2023 15:10",
      "duration": 0.67,
      "energyDelivered": 4.695,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23110607050222117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "06/11/2023 8:05",
      "endTime": "06/11/2023 10:24",
      "duration": 2.32,
      "energyDelivered": 16.254,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23110209120162117921",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "30/10/2023 07:42",
      "endTime": "30/10/2023 09:41",
      "duration": 1.98,
      "energyDelivered": 13.874,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23100910390302117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/10/2023 11:39",
      "endTime": "09/10/2023 13:46",
      "duration": 2.11,
      "energyDelivered": 14.859,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23100310230062117921",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "03/10/2023 11:23",
      "endTime": "03/10/2023 12:51",
      "duration": 1.47,
      "energyDelivered": 10.316,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23091110170242117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "11/09/2023 11:17",
      "endTime": "11/09/2023 13:59",
      "duration": 2.7,
      "energyDelivered": 19.047,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23082812210532117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "28/08/2023 11:59",
      "endTime": "28/08/2023 11:59",
      "duration": 0,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23082811000422117929",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "28/08/2023 12:00",
      "endTime": "28/08/2023 13:21",
      "duration": 1.35,
      "energyDelivered": 9.509,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23082810130042117921",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "28/08/2023 11:13",
      "endTime": "28/08/2023 11:59",
      "duration": 0.78,
      "energyDelivered": 5.423,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23081809170192117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "18/08/2023 10:17",
      "endTime": "18/08/2023 12:48",
      "duration": 2.53,
      "energyDelivered": 17.828,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23080906250082117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/08/2023 7:25",
      "endTime": "09/08/2023 9:37",
      "duration": 2.2,
      "energyDelivered": 15.483,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23072709180352117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "27/07/2023 10:18",
      "endTime": "27/07/2023 13:18",
      "duration": 3,
      "energyDelivered": 20.692,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23071811080112117921",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "18/07/2023 12:08",
      "endTime": "18/07/2023 13:34",
      "duration": 1.44,
      "energyDelivered": 10.124,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23070705400122117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "07/07/2023 6:40",
      "endTime": "07/07/2023 11:22",
      "duration": 4.7,
      "energyDelivered": 20.558,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23070605250402117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "06/07/2023 6:25",
      "endTime": "06/07/2023 7:33",
      "duration": 1.12,
      "energyDelivered": 7.854,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23062005190462117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "20/06/2023 06:19",
      "endTime": "20/06/2023 07:48",
      "duration": 1.48,
      "energyDelivered": 10.261,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23061209520522117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "12/06/2023 10:52",
      "endTime": "12/06/2023 13:58",
      "duration": 3.1,
      "energyDelivered": 14.583,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23051005520402117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "10/05/2023 6:52",
      "endTime": "10/05/2023 9:32",
      "duration": 2.67,
      "energyDelivered": 18.752,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23042609270492117929",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "26/04/2023 10:27",
      "endTime": "26/04/2023 12:00",
      "duration": 1.54,
      "energyDelivered": 10.839,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Remote"
    },
    {
      "sessionId": "C23041810110302117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "18/04/2023 11:11",
      "endTime": "18/04/2023 14:02",
      "duration": 2.85,
      "energyDelivered": 15.3,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23041308570142117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "13/04/2023 09:57",
      "endTime": "13/04/2023 12:46",
      "duration": 2.82,
      "energyDelivered": 19.798,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23031710280532117927",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "17/03/2023 11:28",
      "endTime": "17/03/2023 14:32",
      "duration": 3.07,
      "energyDelivered": 20.542,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Remote"
    },
    {
      "sessionId": "C23030912090162117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/03/2023 13:09",
      "endTime": "09/03/2023 14:56",
      "duration": 1.79,
      "energyDelivered": 11.91,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030910530542117927",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/03/2023 11:53",
      "endTime": "09/03/2023 13:06",
      "duration": 1.21,
      "energyDelivered": 8.302,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030910530282117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/03/2023 11:42",
      "endTime": "09/03/2023 11:42",
      "duration": 0,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030910530202117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/03/2023 11:41",
      "endTime": "09/03/2023 11:42",
      "duration": 0,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030910420562117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/03/2023 11:42",
      "endTime": "09/03/2023 11:53",
      "duration": 0.17,
      "energyDelivered": 993,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030910400312117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/03/2023 11:40",
      "endTime": "09/03/2023 11:41",
      "duration": 0.01,
      "energyDelivered": 31,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C230308140504521179212",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "08/03/2023 15:05",
      "endTime": "08/03/2023 15:05",
      "duration": 0,
      "energyDelivered": 2,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030814010232117929",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "08/03/2023 21:44",
      "endTime": "08/03/2023 21:44",
      "duration": 0,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030814010142117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "03/03/2023 21:02",
      "endTime": "03/03/2023 21:55",
      "duration": 0.88,
      "energyDelivered": 6.128,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030814010042117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "02/03/2023 19:42",
      "endTime": "02/03/2023 19:42",
      "duration": 0.01,
      "energyDelivered": 38,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C230308140005421179210",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "02/03/2023 19:41",
      "endTime": "02/03/2023 19:41",
      "duration": 0.01,
      "energyDelivered": 13,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030814000452117929",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "02/03/2023 19:40",
      "endTime": "02/03/2023 19:40",
      "duration": 0,
      "energyDelivered": 2,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030814000352117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "02/03/2023 19:38",
      "endTime": "02/03/2023 19:39",
      "duration": 0.01,
      "energyDelivered": 22,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030814000262117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/02/2023 22:07",
      "endTime": "22/02/2023 22:13",
      "duration": 0.1,
      "energyDelivered": 0.67,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C23030814000162117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/02/2023 22:04",
      "endTime": "22/02/2023 22:04",
      "duration": 0.01,
      "energyDelivered": 56,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C230308135905621179217",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/02/2023 21:54",
      "endTime": "22/02/2023 22:01",
      "duration": 0.12,
      "energyDelivered": 805,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "EVDisconnected"
    },
    {
      "sessionId": "C230308135904621179216",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/02/2023 21:12",
      "endTime": "22/02/2023 21:12",
      "duration": 0,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "xHrCox",
      "Authorization Type": "Bluetooth",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030813590052117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/02/2023 14:40",
      "endTime": "22/02/2023 17:15",
      "duration": 2.58,
      "energyDelivered": 18.1,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C230308135804421179212",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "15/02/2023 10:18",
      "endTime": "15/02/2023 18:24",
      "duration": 1.1,
      "energyDelivered": 6.961,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23030813580132117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "15/02/2023 08:58",
      "endTime": "15/02/2023 10:15",
      "duration": 1.28,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030813570432117928",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "15/02/2023 08:58",
      "endTime": "15/02/2023 10:14",
      "duration": 1.27,
      "energyDelivered": 0.95,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C230308134604621179210",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "08/03/2023 14:46",
      "endTime": "08/03/2023 14:56",
      "duration": 0.16,
      "energyDelivered": 1.083,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Remote"
    },
    {
      "sessionId": "C23030813460292117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "15/02/2023 07:52",
      "endTime": "15/02/2023 08:00",
      "duration": 0.14,
      "energyDelivered": 161,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C230308134404421179214",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "14/02/2023 12:52",
      "endTime": "14/02/2023 12:53",
      "duration": 0.02,
      "energyDelivered": 0.05,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C230308134402421179212",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "13/02/2023 09:19",
      "endTime": "13/02/2023 10:26",
      "duration": 1.12,
      "energyDelivered": 10.827,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23030813440142117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "10/02/2023 13:17",
      "endTime": "13/02/2023 09:16",
      "duration": 67.98,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030813440042117921",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "10/02/2023 13:17",
      "endTime": "13/02/2023 09:15",
      "duration": 67.97,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C230308134305521179216",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "10/02/2023 13:17",
      "endTime": "13/02/2023 09:13",
      "duration": 67.94,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C230308134304521179214",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "10/02/2023 13:17",
      "endTime": "10/02/2023 13:17",
      "duration": 0.01,
      "energyDelivered": 8,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23030813430042117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "10/02/2023 10:31",
      "endTime": "10/02/2023 13:16",
      "duration": 2.76,
      "energyDelivered": 16.797,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C230308134205521179210",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "10/02/2023 9:16",
      "endTime": "10/02/2023 9:23",
      "duration": 0.12,
      "energyDelivered": 644,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23030813420442117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "31/01/2023 07:54",
      "endTime": "31/01/2023 07:56",
      "duration": 0.03,
      "energyDelivered": 123,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030813420032117921",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "20/01/2023 07:38",
      "endTime": "20/01/2023 10:16",
      "duration": 2.63,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030813410212117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "20/01/2023 07:38",
      "endTime": "20/01/2023 10:14",
      "duration": 2.6,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C230308134004121179210",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "20/01/2023 07:38",
      "endTime": "20/01/2023 10:12",
      "duration": 2.56,
      "energyDelivered": 15.838,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23030813370112117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "13/01/2023 19:43",
      "endTime": "13/01/2023 15:03",
      "duration": 2.33,
      "energyDelivered": 16.381,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C230222134103521179211",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "21/12/2022 10:59",
      "endTime": "21/12/2022 20:26",
      "duration": 2.45,
      "energyDelivered": 15.022,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23022213410262117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "21/12/2022 10:37",
      "endTime": "21/12/2022 10:58",
      "duration": 0.35,
      "energyDelivered": 2.423,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23022213410162117925",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "19/12/2022 14:21",
      "endTime": "19/12/2022 14:21",
      "duration": 0.01,
      "energyDelivered": 9,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23022213410062117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "19/12/2022 14:15",
      "endTime": "19/12/2022 14:19",
      "duration": 0.07,
      "energyDelivered": 444,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C23022213390212117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "09/12/2022 11:26",
      "endTime": "09/12/2022 14:14",
      "duration": 2.81,
      "energyDelivered": 30.424,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23022213390112117921",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "02/12/2022 11:29",
      "endTime": "02/12/2022 11:31",
      "duration": 0.04,
      "energyDelivered": 337,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23022213130362117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "02/12/2022 11:24",
      "endTime": "02/12/2022 11:26",
      "duration": 0.03,
      "energyDelivered": 152,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23022213130272117924",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "02/12/2022 11:21",
      "endTime": "02/12/2022 11:22",
      "duration": 0.02,
      "energyDelivered": 103,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23022213130172117923",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "17/02/2022 14:10",
      "endTime": "17/02/2022 14:10",
      "duration": 0,
      "energyDelivered": 0,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Other"
    },
    {
      "sessionId": "C23022213110402117926",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "22/02/2023 14:05",
      "endTime": "22/02/2023 14:12",
      "duration": 0.11,
      "energyDelivered": 735,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Offline RFID Card",
      "Stop Reason": "Local"
    },
    {
      "sessionId": "C22021713110112117922",
      "Charger Alias": "Centar znanja Međimurske županij",
      "SN": "TACW2240822G2357",
      "startTime": "17/02/2022 21:10",
      "endTime": "17/02/2022 21:12",
      "duration": 0.02,
      "energyDelivered": 84,
      "Total Cost(€)": 0,
      "Session Status": "Free session",
      "User Name": "factory installation user",
      "Authorization Type": "Bluetooth",
      "Stop Reason": "Local"
    }
  ]

  useEffect(() => {
    // Funkcija za generiranje podataka za zadnjih 12 mjeseci
    const generateMonthlyData = (sessions, now) => {
      const monthlyArray = [];
      for (let i = 11; i >= 0; i--) {
        const month = moment(now).subtract(i, 'months').format('MM.YYYY');
        monthlyArray.push({ month, value: 0 });
      }

      sessions.forEach((session) => {
        const sessionStart = moment(session.startTime, 'DD/MM/YYYY HH:mm');
        const sessionEnd = moment(session.endTime, 'DD/MM/YYYY HH:mm');

        if (sessionEnd.isAfter(moment(now).subtract(12, 'months')) && sessionStart.isBefore(now)) {
          const month = sessionStart.format('MM.YYYY');
          const index = monthlyArray.findIndex((item) => item.month === month);
          if (index !== -1) {
            monthlyArray[index].value += session.energyDelivered;
          }
        }
      });

      return monthlyArray;
    };

    // Procesiranje podataka
    const processData = () => {
      const now = moment();

      const monthly = generateMonthlyData(chargingSessions, now);
      setMonthlyData(monthly);

      // Ažuriranje stanja baterije (primjerice, inicijalno 100%)
      // Ovdje možete dodati logiku za ažuriranje SoC baterije na temelju potrošnje
      // Trenutno se SoC postavlja na 100% nakon procesiranja podataka
      setBatterySoC(100);
    };

    processData();
  }, [chargingSessions]);

  // Funkcija za formatiranje postotka baterije
  const formatBatterySoC = () => {
    return `${batterySoC.toFixed(1)}%`;
  };

  // Stilizacija komponenta
  const containerStyle = {
    flex: 1,
    padding: '20px 0',
    overflowY: 'auto',
    backgroundColor: '#f5f5f5',
  };

  const chartWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '40px',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '300px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333333',
  };

  const explanationStyle = {
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: '10px',
    marginBottom: '20px',
    maxWidth: '90%',
    color: '#666666',
  };

  const batteryStatusStyle = {
    maxWidth: '300px',
    margin: '0 auto 40px',
    textAlign: 'center',
  };

  const batteryBarStyle = {
    width: '100%',
    height: '25px',
    backgroundColor: '#ddd',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  const batteryFillStyle = {
    height: '100%',
    transition: 'width 0.5s ease-in-out',
  };

  const batteryPercentageStyle = {
    marginTop: '5px',
    fontWeight: 'bold',
    color: '#555555',
  };

  return (
    <div style={containerStyle}>
      {/* Battery Status Section */}
      {/* <div style={batteryStatusStyle}>
        <h3>Status baterije</h3>
        <div style={batteryBarStyle}>
          <div
            style={{
              ...batteryFillStyle,
              width: `${batterySoC}%`,
              backgroundColor: batterySoC > 20 ? '#4caf50' : '#f44336',
            }}
          ></div>
        </div>
        <p style={batteryPercentageStyle}>{formatBatterySoC()}</p>
      </div> */}

      {/* Last 12 Months Chart */}
      <h2 style={titleStyle}>Punjenje električnih vozila - Zadnjih 12 mjeseci</h2>
      <div style={chartWrapperStyle}>
        <div style={chartContainerStyle}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={monthlyData}
              margin={{ top: 10, right: 30, left: 20, bottom: 40 }}
            >
              <CartesianGrid stroke="#ccc" />
              <XAxis
                dataKey="month"
                label={{
                  value: 'Mjesec (MM.YYYY)',
                  position: 'insideBottom',
                  offset: -5,
                }}
                tick={{ fontSize: 12 }}
                angle={-45}
                textAnchor="end"
                height={60}
                interval={0}
              />
              <YAxis
                label={{
                  value: 'Punjenje (kWh)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 10,
                }}
                tick={{ fontSize: 12 }}
              />
              <Tooltip labelFormatter={(value) => `Mjesec: ${value}`} />
              <Legend verticalAlign="top" height={36} />
              <Bar dataKey="value" fill="#ff7300" name="Punjenje (kWh)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <p style={explanationStyle}>
          Ovaj graf prikazuje količinu energije korištene za punjenje električnih vozila u
          posljednjih 12 mjeseci, prikazujući potrošnju za svaki mjesec.
        </p>
      </div>

      {/* Detaljno Objašnjenje */}
      {/* <div className="detailed-explanation">
        <p>
          <strong>Što je Punjenje Električnih Vozila (EV Charging)?</strong>
        </p>
        <p>
          Punjenje električnih vozila (EV Charging) odnosi se na proces isporuke električne energije
          baterijama električnih vozila kako bi se omogućilo njihovo vožnje. Ovo je ključni dio
          infrastrukture za održivu mobilnost, omogućujući korisnicima električnih vozila da se
          lako i učinkovito pune.
        </p>
        <p>
          <strong>Zašto je Punjenje Električnih Vozila važno?</strong>
        </p>
        <p>
          Punjenje električnih vozila ima nekoliko ključnih prednosti:
        </p>
        <ul>
          <li><strong>Smanjenje emisija stakleničkih plinova:</strong> Električna vozila ne ispuštaju CO₂ tijekom vožnje, što doprinosi smanjenju ukupnih emisija.</li>
          <li><strong>Ušteda troškova:</strong> Električna vozila često su ekonomičnija za održavanje i punjenje u usporedbi s vozilima s unutarnjim izgaranjem.</li>
          <li><strong>Održiva mobilnost:</strong> Razvoj mreže za punjenje potiče prelazak na električnu mobilnost, što vodi ka održivijem transportnom sustavu.</li>
          <li><strong>Kompatibilnost s obnovljivim izvorima energije:</strong> Punjenje električnih vozila može se uskladiti s proizvodnjom energije iz obnovljivih izvora poput solarne i vjetroelektrane.</li>
        </ul>
        <p>
          Implementacijom učinkovitih sustava za punjenje, omogućujemo većem broju ljudi da koriste električna vozila, čime se pridonosimo očuvanju okoliša i poboljšanju kvalitete zraka.
        </p>
      </div> */}
    </div>
  );
};

export default EVChargingScreen;
